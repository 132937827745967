export const API_ChangeStatus = async (id, status) => {
  var apiUrl = process.env.REACT_APP_API_URL;
  debugger;
  const res = await fetch(
    `${apiUrl}/api/transactions/status/change/${id}/${status}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }
  );
  return await res.json();
};
