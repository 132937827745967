import { API_GetMyCart } from "api-services/Cart/CartService";
import { Checkout } from "api-services/Cart/Checkout";
import { getCurrencySymbol } from "helpers/helper";
import CheckOutModal from "components/cart/checkoutmodal";
import { addRangeToCart, updateUser } from "slices/userSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { faCartShopping, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { TransactionStatus } from "helpers/enum";

export const UserProfileMenu = ({ user }) => {
  const dispatch = useDispatch();
  const [showMyCart, setShowMyCart] = useState(false);
  const [totalCheckOutCost, setTotalCheckOutCost] = useState(0);
  const [currency, setCurrency] = useState("USD");
  const [isLoading, setIsLoading] = useState(false);
  const [showCartAnimation, setShowCartAnimation] = useState(false);
  const [checkOutText, setCheckOutText] = useState("Checkout");
  const myCart = useSelector((state) => state.user.myCart);
  const { logout } = useAuth0();
  useEffect(() => {
    dispatch(updateUser(user));
    if (myCart && myCart.length > 0) {
      var buyableItems = myCart.filter((item) => {
        return item.status == TransactionStatus.AddToCart;
      });
      const sum = buyableItems.reduce((accumulator, item) => {
        setCurrency(item.currency);
        return accumulator + item.amount;
      }, 0);

      setTotalCheckOutCost(sum.toFixed(0));
    } else {
      setTotalCheckOutCost(0);
    }
    handleCartAnimation();
  }, [dispatch, myCart]);
  async function fetchData(forceFetch) {
    if (myCart && (!myCart.length || forceFetch)) {
      debugger;
      var userApiResult = await API_GetMyCart(user);
      debugger;
      if (userApiResult.success) {
        const data = userApiResult.result?.usersTransactions;
        var apiCart = [];
        data?.forEach((element) => {
          if (
            element.status == TransactionStatus.AddToCart ||
            element.status == TransactionStatus.Expired
          ) {
            // only AddToCart = 1
            apiCart.push(element);
          }
        });
        dispatch(addRangeToCart(apiCart));
      }
    }
  }
  useEffect(() => {
    // update myCart by UserAPI

    fetchData();

    console.log("UserProfile useEffect 2");
    handleCartAnimation();
  }, [user]);
  const handleCartAnimation = () => {
    setShowCartAnimation(true);
    setTimeout(() => {
      setShowCartAnimation(false);
    }, 2000);
  };
  const showCart = () => {
    setShowMyCart(true);
  };
  const handleCheckOut = async () => {
    setIsLoading(true);
    debugger;
    // fetch api
    const proceed = await Checkout(myCart, user);
    if (proceed?.success) {
      setIsLoading(false);
      if (proceed.message) {
        // show user a expired domain information
        toast(proceed.message, {
          autoClose: 10000,
          type: "error",
        });
        debugger;
        fetchData(true); // forcefetch
      } else {
        setCheckOutText("Redirecting 🔒...");
        setTimeout(() => {
          window.location.href = proceed.stripeURL;
        }, 1000);
      }
    } else if (proceed?.message) {
      setIsLoading(false);
    }
  };

  return (
    user && (
      <div className="d-md-inline-block">
        <button
          onClick={showCart}
          className="btn btn-primary btn-sm-slim text-uppercase border border-white mr-3 btn-border-primary"
        >
          <FontAwesomeIcon
            className={`icon-fontawesome-fix ${
              showCartAnimation ? "rotate-animate" : ""
            }`}
            icon={faCartShopping}
          />
          My Cart{" "}
          <span className="badge badge-light">{myCart && myCart.length}</span>
        </button>
        <Modal
          show={showMyCart}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Checkout
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CheckOutModal />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowMyCart(false)}>Close</Button>
            <Button
              className={
                checkOutText !== "Checkout"
                  ? "btn btn-success"
                  : "btn btn-primary"
              }
              disabled={totalCheckOutCost === 0}
              onClick={handleCheckOut}
            >
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              <FontAwesomeIcon
                className="icon-fontawesome-fix"
                icon={checkOutText !== "Checkout" ? faCheck : faCartShopping}
              />
              {(currency && getCurrencySymbol("en-US", currency)) +
                totalCheckOutCost}{" "}
              - {checkOutText}
            </Button>
          </Modal.Footer>
          <div className="float-left p-1">
            <img
              src="./assets/images/stripe.svg"
              alt="Powered by Stripe"
              className="stripe img-fluid"
              height={15}
            />
          </div>
        </Modal>
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-primary btn-sm-slim text-uppercase border border-white dropdown-toggle btn-border-primary"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src={user.picture}
              className="mr-2 rounded-circle"
              referrerPolicy="no-referrer"
              width={20}
              height={20}
            />{" "}
            {user.name}
          </button>
          <div className="dropdown-menu">
            <Link to="/profile" className="dropdown-item">
              Profile
            </Link>
            <div className="dropdown-divider" />
            <a
              className="dropdown-item"
              href=""
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              Logout
            </a>
          </div>
        </div>
      </div>
    )
  );
};
export default UserProfileMenu;
