export const API_AddToCart = async (cartItem) => {
  var body = JSON.stringify(cartItem);
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/api/transactions/cart/add`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body,
    }
  );
  return await res.json();
};
