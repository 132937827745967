export const API_GetTransactions = async () => {
  var apiUrl = process.env.REACT_APP_API_URL;
  debugger;
  const res = await fetch(`${apiUrl}/api/transactions/get`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  return await res.json();
};
