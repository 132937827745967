import MainPage from "components/layouts/MainPage";
import MainSearch from "components/search/MainSearch";

export default function Home() {
  return (
    <>
      <header className="xParticles">
        <div className="container-fluid py-5">
          <h1 className="text-center fw-semibold pt-5 pb-3 h-title">
            Secure Your <span className="yellow">Notary</span>
            WEB3 Domain{" "}
          </h1>
          <h3 className="font-weight-normal text-center py-4 subtitle">
            Own your identity in the digital world.
          </h3>
          <div
            className="col-lg-8 col-md-10 col-12 mx-auto"
            style={{ zIndex: 1000 }}
          >
            {/* FORM START */}

            <MainSearch />

            {/* FORM END */}
            {/* <form className="row inline-form">
              <div className="col-9 p-0">
                <input
                  type="text"
                  className="form-control form-control-lg rounded-0"
                  placeholder="example.Notary"
                />
              </div>
              <div className="col-3 p-0">
                <button
                  type="submit"
                  className="btn btn-secondary btn-lg btn-block rounded-0"
                >
                  Search
                </button>
              </div>
            </form> */}
            <p className="text-center mt-4">
              {" "}
              Secure your domaine :{" "}
              <span className="text-underline px-3">.Notary</span>
              <span className="text-underline px-3">.Notarypublic</span> and
              more...
            </p>
            <div className="row mt-5 benefits">
              <div className="col-4 text-center">
                <div className="d-inline-block text-center">
                  <img
                    className="mb-2 h-icons"
                    src="./assets/images/hicon1.svg"
                    alt="Icon 1"
                  />
                  <p>
                    <span>No renewal</span>
                    <br />
                    <span className="text-lg">fees</span>
                  </p>
                </div>
              </div>
              <div className="col-4 text-center">
                <div className="d-inline-block text-center">
                  <img
                    className="mb-2 h-icons"
                    src="./assets/images/hicon2.svg"
                    alt="Icon 1"
                  />
                  <p>
                    <span>No gas or</span>
                    <br />
                    <span className="text-lg">mint fees</span>
                  </p>
                </div>
              </div>
              <div className="col-4 text-center">
                <div className="d-inline-block text-center">
                  <img
                    className="mb-2 h-icons"
                    src="./assets/images/hicon3.svg"
                    alt="Icon 1"
                  />
                  <p>
                    <span>Starting at</span>
                    <br />
                    <span className="text-lg">49+</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Main front text*  END*/}

      <MainPage />
    </>
  );
}
