import { updateDBUser } from "slices/userSlice";
import { faLock, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge, Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Blockchain } from "helpers/enum";
import { pascalCaseToSpaces } from "helpers/helper";

export default function WalletDetails() {
  const dispatch = useDispatch();
  const dbUser = useSelector((state) => state.user.dbUser);
  const handleChange = (e, entityName) => {
    dispatch(updateDBUser({ ...dbUser, ...{ [entityName]: e.target.value } }));
  };
  console.log("BlockChain", Object.keys(Blockchain));
  return (
    <>
      <div className="col-sm-12">
        <form>
          <div className="form-row">
            <div className="col-md-12 mb-3">
              <p className="float-left">
                Please mention below the Crypto Wallet address on which you want
                to transfer Web3 Domain...
              </p>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon className="icon-width " icon={faWallet} />
                  <Badge bg="None">Wallet Address</Badge>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Wallet Address"
                  aria-label="Wallet Address"
                  value={dbUser?.cryptoWalletAddress || ""}
                  onChange={(e) => handleChange(e, "cryptoWalletAddress")}
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon className="icon-width " icon={faLock} />
                  <Badge bg="None">Select your Blockchain</Badge>
                </InputGroup.Text>
                <Form.Select
                  aria-label="Select your Blockchain"
                  className="form-control"
                  value={dbUser?.blockchain || ""}
                  onChange={(e) => handleChange(e, "blockchain")}
                >
                  {Object.keys(Blockchain).map((key) => (
                    <option key={Blockchain[key]} value={Blockchain[key]}>
                      {pascalCaseToSpaces(key)}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
