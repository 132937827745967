import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function ConfirmModal({
  showConfirm,
  handleConfirm,
  handleConfirmClose,
}) {
  return (
    <>
      <Modal
        show={showConfirm}
        onHide={() => {
          handleConfirmClose(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attention</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary btn-sm-slim "
            onClick={() => {
              handleConfirmClose(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary btn-sm-slim "
            onClick={() => handleConfirm(true)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
