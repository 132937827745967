import { updateDBUser } from "slices/userSlice";
import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useDispatch, useSelector } from "react-redux";

const ProfileDetails = ({ userProfile }) => {
  const dbUser = useSelector((state) => state.user.dbUser);

  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();
  const handleChange = (e, entityName) => {
    dispatch(updateDBUser({ ...dbUser, ...{ [entityName]: e.target.value } }));
  };
  const handleDropdownChange = (dropdownValue, entityName) => {
    setSelected(dropdownValue);
    dispatch(updateDBUser({ ...dbUser, ...{ [entityName]: dropdownValue } }));
  };

  useEffect(() => {
    setSelected(dbUser?.billing_Country);
  }, [dbUser]);
  if (!userProfile && !dbUser) {
    return <></>;
  }
  return (
    <>
      {dbUser?.name && (
        <>
          <div className="col-sm-6">
            <form>
              <div className="form-row">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon3">
                      Name
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    value={dbUser?.name || ""}
                    placeholder="Name"
                    disabled
                  />
                </div>

                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon3">
                      Email
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    value={dbUser?.email || ""}
                    placeholder="Email"
                    disabled
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon3">
                      Phone
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    value={dbUser?.phone || ""}
                    onChange={(e) => handleChange(e, "phone")}
                    placeholder="+1 123 1234 1234"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-sm-6">
            <div className="form-row">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon3">
                    Billing Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={dbUser?.billing_Name || ""}
                  onChange={(e) => handleChange(e, "billing_Name")}
                  placeholder="Billing Name..."
                />
              </div>

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon3">
                    Billing Email
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={dbUser?.billing_Email || ""}
                  onChange={(e) => handleChange(e, "billing_Email")}
                  placeholder="Billing Email..."
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon3">
                    Country
                  </span>
                </div>
                <ReactFlagsSelect
                  className="form-control country-dropdown"
                  selected={selected}
                  placeholder="Select Country"
                  searchable
                  onSelect={(code) =>
                    handleDropdownChange(code, "billing_Country")
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ProfileDetails;
