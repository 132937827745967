import React from "react";

const UserProfileCard = ({ user }) => {
  if (!user) {
    return <></>;
  }
  return (
    <div className="card mt-5">
      <div className="card-body">
        <div className="row">
          <div className="col-md-4 text-center">
            <img
              src={user?.picture}
              referrerPolicy="no-referrer"
              className="img-fluid rounded-circle"
            />
          </div>
          <div className="col-md-8 text-center text-sm-left">
            <h4 className="card-title">{user?.name}</h4>
            <h6 className="card-subtitle mb-2 text-muted">{user?.email}</h6>
            <p className="card-text">
              Be sure to link your digital wallet to your profile.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileCard;
