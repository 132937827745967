import { API_AddToCart } from "api-services/Cart/AddToCart";
const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  user: {},
  isAuthenticated: false,
  myCart: [],
  dbUser: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
      state.isAuthenticated = true;
    },
    addRangeToCart: (state, action) => {
      debugger;
      //remove redundant object
      state.myCart = action.payload;
    },
    addToCart: (state, action) => {
      //remove redundant object
      // state.myCart = state.myCart.filter(function (cartItem, index) {
      //   return cartItem?.name !== action.payload?.name;
      // });
      state.myCart = state.myCart.filter(function (cartItem) {
        return cartItem?.transactionID != null;
      });
      // update the database
      async function updateMyCart() {
        debugger;
        var apiData = await API_AddToCart(action.payload);
        debugger;
        if (apiData?.success) {
          state.myCart = state.myCart.filter(function (cartItem) {
            return cartItem?.transactionID != null;
          });
          // apiData?.usersTransactions?.forEach((item) => {
          //   if (item.status == 1) {
          //     // 1 = Add to cart
          //     state.myCart.push(item);
          //   }
          // });
        }
      }
      updateMyCart();
    },
    removeItemFromCart: (state, action) => {
      debugger;
      state.myCart = state.myCart.filter(function (cartItem) {
        return cartItem?.transactionID !== action.payload;
      });
    },
    updateWalletAddressInCart: (state, action) => {
      const { cartItem, walletAddress } = action.payload;
      state.myCart.forEach((item, index) => {
        if (state.myCart[index].name === cartItem.name) {
          state.myCart[index].walletAddress = walletAddress;
        }
      });
    },
    updateDBUser: (state, action) => {
      state.dbUser = action.payload;
    },
  },
});

export const {
  updateUser,
  addToCart,
  removeItemFromCart,
  updateWalletAddressInCart,
  addRangeToCart,
  updateDBUser,
} = userSlice.actions;

export default userSlice.reducer;
