import { Dropdown } from "react-bootstrap";

export default function CustomDropdown({
  options,
  selectedOption,
  onOptionSelect,
}) {
  debugger;
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="secondary"
        id="dropdown-basic"
        className="btn-sm container-fluid"
      >
        {selectedOption.label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item
            key={option.value}
            onClick={() => onOptionSelect(option)}
            selected={selectedOption.value === option.value}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
