export const API_UpdateUserProfile = async (userObj) => {
  var body = JSON.stringify(userObj);
  const res = await fetch(`${process.env.REACT_APP_API_URL}/api/users/update`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body,
  });
  return await res.json();
};
