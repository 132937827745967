export const TransactionStatus = {
  None: 0,
  AddToCart: 1,
  Paid: 2,
  Transferred: 3,
  Complete: 4,
  Remove: 5,
  Expired: 6,
};
export const Blockchain = {
  None: 0,
  Polygon: 1,
  BinanceSmartChain: 2,
  Aurora: 3,
  Cronos: 4,
};
