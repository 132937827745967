import { API_ChangeStatus } from "api-services/Transactions/ChangeStatus";
import ConfirmModal from "components/ui-components/confirm-modal";
import CustomDropdown from "components/ui-components/dropdown";
import { PaymentStatusBadge } from "helpers/helper";
import React, { useState } from "react";
import { Badge, Button, Modal, Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ChangeStatus({
  showModal,
  transaction,
  handleShowModal,
}) {
  debugger;
  const [selectedOption, setSelectedOption] = useState({
    value: "0",
    label: "None",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [userConfirmed, setUserConfirmed] = useState(false);
  if (!transaction) {
    debugger;
    return <></>;
  }
  const options = [
    { value: "2", label: "Paid" },
    { value: "3", label: "Transferred" },
    { value: "5", label: "Remove" },
    { value: "6", label: "Expired" },
  ];
  const handleOptionSelect = (option) => {
    debugger;
    setSelectedOption(option);
  };
  const handleConfirmClose = (show) => {
    setShowConfirm(show);
  };
  const handleConfirm = async (confirm) => {
    setUserConfirmed(confirm);
    if (confirm) {
      // API and Loading..
      debugger;
      setIsLoading(true);
      handleConfirmClose(false);
      var result = await API_ChangeStatus(transaction.id, selectedOption.value);
      if (result.success) {
        toast("The transaction status has been changed.", {
          autoClose: 3000,
          type: "success",
        });
      } else {
        toast("something went wrong..", {
          autoClose: 3000,
          type: "error",
        });
      }

      setIsLoading(false);
    }
  };
  const onStatusChange = () => {
    setShowConfirm(true);
  };
  return (
    <>
      <ConfirmModal
        showConfirm={showConfirm}
        handleConfirmClose={handleConfirmClose}
        handleConfirm={handleConfirm}
      />
      <Modal
        show={showModal}
        size="lg"
        onHide={() => handleShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Changing Status of the Domain</h5>
          <div
            className="container-fluid "
            style={{ overflowX: "auto", height: "300px" }}
          >
            <Table striped bordered hover className="font-monts-400">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Domain</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Change Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{transaction.id}</td>
                  <td>{transaction.itemName}</td>
                  <td>
                    <Badge
                      bg={PaymentStatusBadge(transaction.status).type}
                      className="text-white"
                    >
                      {PaymentStatusBadge(transaction.status).text}
                    </Badge>
                  </td>
                  <td>{transaction.amount}</td>
                  <td>
                    <CustomDropdown
                      options={options}
                      selectedOption={selectedOption}
                      onOptionSelect={handleOptionSelect}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-sm-slim "
            onClick={() => handleShowModal(false)}
          >
            Close
          </Button>
          <Button
            className="btn btn-success btn-sm-slim "
            onClick={onStatusChange}
          >
            {isLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Change Status
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
