import { configureStore, combineReducers } from "@reduxjs/toolkit";

import user from "slices/userSlice";
import counter from "slices/counterSlice";

const combineReducer = combineReducers({
  counter,
  user,
});

export const store = configureStore({
  reducer: combineReducer,
  devTools: true,
});

export default store;
