import React from "react";
import { Badge } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export default function Cancel() {
  // First fetch information of TransactionID, show incorrect Error if transactionID is wrong
  // If Transaction ID is correct, mark it Processed
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  // Access the query string parameters using the get method
  const transactionID = queryString.get("transaction");
  return (
    <>
      <div className="contain-box">
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <div className="container-fluid">
                <div className="row">
                  <div className="container mt-5">
                    <div className="card mt-5">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 mt-5 text-center">
                            <h6>
                              Opps!{" "}
                              <Badge className="bg-danger text-white">
                                {transactionID}
                              </Badge>{" "}
                              has been cancelled, The selected domains are still
                              in your cart.
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
