import ReactDataGrid from "@inovua/reactdatagrid-community";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "@inovua/reactdatagrid-community/index.css";
import { getCurrencySymbol } from "helpers/helper";
import { PaymentStatusBadge } from "helpers/helper";
import { Badge } from "react-bootstrap";
import moment from "moment/moment";

import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import ChangeStatus from "./Admin/change-status";
import { API_GetTransactions } from "api-services/Transactions/GetTransactions";

export default function AdminPanel() {
  window.moment = moment;
  const [showModal, setShowModal] = useState(false);
  const [selectedObj, setSelectedObj] = useState({});
  const [transactionList, setTransactionList] = useState([]);

  const STATUS = [
    {
      id: 0,
      label: (
        <Badge bg={PaymentStatusBadge(0).type} className="text-white">
          {PaymentStatusBadge(0).text}
        </Badge>
      ),
    },
    {
      id: 1,
      label: (
        <Badge bg={PaymentStatusBadge(1).type} className="text-white">
          {PaymentStatusBadge(1).text}
        </Badge>
      ),
    },
    {
      id: 2,
      label: (
        <Badge bg={PaymentStatusBadge(2).type} className="text-white">
          {PaymentStatusBadge(2).text}
        </Badge>
      ),
    },
    {
      id: 3,
      label: (
        <Badge bg={PaymentStatusBadge(3).type} className="text-white">
          {PaymentStatusBadge(3).text}
        </Badge>
      ),
    },
    {
      id: 4,
      label: (
        <Badge bg={PaymentStatusBadge(4).type} className="text-white">
          {PaymentStatusBadge(4).text}
        </Badge>
      ),
    },
    {
      id: 5,
      label: (
        <Badge bg={PaymentStatusBadge(5).type} className="text-white">
          {PaymentStatusBadge(5).text}
        </Badge>
      ),
    },
    {
      id: 6,
      label: (
        <Badge bg={PaymentStatusBadge(6).type} className="text-white">
          {PaymentStatusBadge(6).text}
        </Badge>
      ),
    },
  ];
  const columns = [
    {
      name: "id",
      header: "ID",
      defaultWidth: 50,
      type: "number",
      filterable: true,
    },
    { name: "itemName", defaultFlex: 1, maxWidth: 200, header: "Domain" },
    { name: "userID", defaultFlex: 1, maxWidth: 80, header: "UserID" },
    {
      name: "transactionID",
      defaultFlex: 1,
      maxWidth: 230,
      header: "Transaction ID",
      render: ({ value }) => (
        <Badge className="secretKey ">{value || ""}</Badge>
      ),
    },

    {
      name: "status",
      defaultFlex: 1,
      maxWidth: 180,
      header: "Status",
      type: "select",
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: "Select Status",
        dataSource: STATUS,
      },
      render: ({ value, data }) => (
        <Badge bg={PaymentStatusBadge(value).type} className="text-white">
          {PaymentStatusBadge(value).text}
        </Badge>
        // <>{PaymentStatusBadge(value).text}</>
      ),
    },
    {
      name: "itemCost",
      defaultFlex: 1,
      maxWidth: 80,
      header: "Freename Cost",
      render: ({ value, data }) =>
        `${getCurrencySymbol("en-US", data.currency)}${value}`,
    },
    {
      name: "profit",
      defaultFlex: 1,
      maxWidth: 80,
      header: "Profit",
      render: ({ value, data }) =>
        `${getCurrencySymbol("en-US", data.currency)}${value}`,
    },
    {
      name: "stripeFee",
      defaultFlex: 1,
      maxWidth: 80,
      header: "Stripe",
      render: ({ value, data }) =>
        `${getCurrencySymbol("en-US", data.currency)}${value}`,
    },
    {
      name: "amount",
      defaultFlex: 1,
      maxWidth: 80,
      header: "Amount",
      render: ({ value, data }) =>
        `${getCurrencySymbol("en-US", data.currency)}${value}`,
    },
    {
      name: "createdOn",
      header: "Created On",
      defaultFlex: 1,
      minWidth: 200,
      filterable: true,
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the after field
        return {
          dateFormat: "MM-DD-YYYY",
          cancelButton: false,
          highlightWeekends: false,
          placeholder:
            index == 1
              ? "Created date is before..."
              : "Created date is after...",
        };
      },
      render: ({ value, cellProps }) => {
        return moment(value).format("MM-DD-YYYY");
      },
    },
  ];
  const filterValue = [
    { name: "id", operator: "gte", type: "number" },
    {
      name: "transactionID",
      operator: "contains",
      type: "string",
    },
    {
      name: "status",
      operator: "eq",
      type: "select",
    },
    {
      name: "createdOn",
      operator: "afterOrOn",
      type: "date",
    },
  ];
  const onSelectionChange = useCallback(
    ({ data }) => {
      console.log("SELECTION", data);
      debugger;
      if (transactionList) {
        const obj = transactionList.filter((x) => {
          return x.id === data.id;
        });
        debugger;
        setSelectedObj(obj[0]);
        setShowModal(true);
      }
    },
    [transactionList, setSelectedObj]
  );

  const handleShowModal = (show) => {
    if (!show) {
      fetchTransactionsData(true);
    }
    setShowModal(show);
  };
  const gridStyle = { minHeight: 450 };
  async function fetchTransactionsData(forceFetch) {
    if (forceFetch) {
      debugger;
      var transactionResult = await API_GetTransactions();
      debugger;
      if (transactionResult.success) {
        setTransactionList(transactionResult.result);
      }
    }
  }
  useEffect(() => {
    fetchTransactionsData(true);
  }, []);
  return (
    <>
      {transactionList.length > 0 && (
        <>
          <ReactDataGrid
            idProperty="adminpanel-transactions"
            pagination
            defaultFilterValue={filterValue}
            defaultLimit={10}
            style={gridStyle}
            columns={columns}
            dataSource={transactionList}
            enableSelection={true}
            onSelectionChange={onSelectionChange}
          />
          <ChangeStatus
            showModal={showModal}
            handleShowModal={handleShowModal}
            transaction={selectedObj}
          />
        </>
      )}
    </>
  );
}
