import { Badge } from "react-bootstrap";

export default function ErrorPage() {
  return (
    <>
      <div className="contain-box">
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <div className="container-fluid">
                <div className="row">
                  <div className="container mt-5">
                    <div className="card mt-5">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 mt-5 text-center">
                            <h6>
                              Opps!{" "}
                              <Badge className="bg-danger text-white">
                                404
                              </Badge>{" "}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
