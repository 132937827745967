import { API_RemoveItemFromCart } from "api-services/Cart/CartService";
import { getCurrencySymbol } from "helpers/helper";
import { addRangeToCart } from "slices/userSlice";
import { useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { useDispatch, useSelector } from "react-redux";
import { TransactionStatus } from "helpers/enum";
import { DisplayMessage } from "helpers/displayMessageTexts";
const CheckOutModal = () => {
  const myCart = useSelector((state) => state.user.myCart);
  const [removeLoadingButtonID, setRemoveLoadingButtonID] = useState("");
  const dispatch = useDispatch();
  const handleRemoveItemFromCart = (transactionID) => {
    debugger;
    // transactionID = NOT GUID its PK of Transactions Table
    var result = async function () {
      debugger;
      var stateObj = [];
      setRemoveLoadingButtonID(transactionID);
      var apiData = await API_RemoveItemFromCart(transactionID);
      setRemoveLoadingButtonID(0);
      if (apiData?.success) {
        debugger;

        apiData?.result?.forEach((item) => {
          if (
            item.status == TransactionStatus.AddToCart ||
            item.status == TransactionStatus.Expired
          ) {
            // 1 = Add to cart
            stateObj.push(item);
          }
        });
        return [...stateObj];
      }
    };
    result().then((res) => {
      dispatch(addRangeToCart(res));
    });
  };

  return (
    <ListGroup variant="flush">
      {myCart &&
        myCart.map((cartItem, index) => (
          <Container key={index}>
            <Row>
              <Col sm={8}>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <span className=" text-light">{cartItem?.itemName}</span>
                      {cartItem?.status == TransactionStatus.Expired && (
                        <>
                          <span
                            className="badge bg-danger  text-light"
                            title={DisplayMessage.Cart.Expired_Message(
                              cartItem.itemName
                            )}
                          >
                            EXPIRED
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder="Cryto Wallet Address"
                    value={myCart[index]?.walletAddress}
                    onChange={(e) =>
                      handleUpdateWalletAddressInCart(cartItem, e.target.value)
                    }
                  /> */}
                </div>
              </Col>
              <Col sm={4}>
                <div className="input-group-prepend float-right">
                  <span className="btn-sm font-weight-bold">
                    {cartItem?.currency &&
                      getCurrencySymbol("en-US", cartItem?.currency) +
                        cartItem?.amount}
                  </span>
                  <Button
                    onClick={() =>
                      handleRemoveItemFromCart(cartItem?.id, index)
                    }
                    variant="outline-danger btn-sm mr-3"
                  >
                    {removeLoadingButtonID === cartItem?.id && (
                      <Spinner
                        className="mr-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    Remove
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        ))}
    </ListGroup>
  );
};
export default CheckOutModal;
