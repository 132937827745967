import { PaymentStatusBadge, getCurrencySymbol } from "helpers/helper";
import React from "react";
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function MyOrders() {
  const { usersTransactions } = useSelector((state) => state.user.dbUser);
  console.log("User Transactions", usersTransactions);
  return (
    <>
      <table className="table table-sm table-hover table-bordered table-striped table-orders">
        <caption>
          Please contact us, If you find any mistake.{" "}
          <Link className="btn-sm-slim btn-primary" to="/contact-us">
            Contact us
          </Link>
        </caption>
        <thead className="thead-light">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Secret</th>
            <th scope="col">Domain</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {usersTransactions &&
            usersTransactions?.map((item, index) => (
              <tr key={index}>
                <td title={item?.id}>{item?.id}</td>
                <td title={item?.transactionID}>
                  <Badge className="secretKey bg-gradient-protected">
                    {item?.transactionID || ""}
                  </Badge>
                </td>
                <td>{item.itemName}</td>
                <td>
                  {item?.currency &&
                    getCurrencySymbol("en-US", item?.currency) + item?.amount}
                </td>

                <td>
                  <Badge bg={PaymentStatusBadge(item.status).type}>
                    {PaymentStatusBadge(item.status).text}
                  </Badge>
                </td>
                <td>{new Date(item.createdOn).toLocaleDateString("en-US")}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
