export const FaqData = [
  {
    id: 1,
    question: "What is Web3?",
    catagory: "faq-web3",
    answer:
      "Web3, also known as the decentralized web, is the next generation of the internet. It is an evolution of the current Web2 model, which is centralized and controlled by a few large corporations. In contrast, Web3 is decentralized and operates on a peer-to-peer network. The key features of Web3 are the use of blockchain technology, smart contracts, and decentralized applications (dApps). Blockchain technology provides a secure and transparent ledger system that can be used for various purposes, such as managing digital assets and identity verification. Smart contracts are self-executing contracts with the terms of the agreement between buyer and seller being directly written into lines of code, which ensure that the terms of the contract are fulfilled automatically. dApps are applications built on a decentralized network, such as Ethereum or Binance Smart Chain, which allow for decentralized finance (DeFi) and other use cases.eb3 has the potential to transform industries by removing the need for intermediaries and allowing for more efficient and secure transactions. It also provides greater user privacy and control over personal data. Examples of Web3 projects include decentralized exchanges, non-fungible tokens (NFTs), and decentralized social media platforms.",
  },
  {
    id: 2,
    question: "What is a Web3 Domain?",
    catagory: "faq-web3",
    answer:
      "A Web3 domain is a decentralized domain name that exists on the blockchain. Traditional domain names, such as .com or .org, are controlled by centralized entities, such as ICANN, and require a domain registrar to manage them. In contrast, Web3 domains are owned and controlled by their users and can be used to interact with decentralized applications (dApps) on the blockchain. Web3 domains are implemented using the Ethereum Name Service (ENS) protocol, which is a smart contract system that allows users to register human-readable names as Ethereum addresses. This means that users can send cryptocurrency and interact with dApps using a user-friendly domain name, rather than a long string of hexadecimal characters. For example, a user could register the Web3 domain 'mybusiness.eth' and use it as an address to receive cryptocurrency payments or interact with a decentralized marketplace. Users can also attach metadata to their Web3 domains, such as social media profiles or other contact information, making it easier for others to find and interact with them on the blockchain. Web3 domains have the potential to create a more user-friendly and decentralized internet, where users have greater control over their digital identities and assets. As an instructor, I would encourage students to learn more about Web3 domains and consider their potential use cases in industries such as finance, social media, and e-commerce.",
  },
  {
    id: 3,
    question: "How to search and buy a domain?",
    catagory: "faq-purchases",
    answer:
      "The process is simple. Enter your desired domain, followed by one of our TLDs (johndoe.notary). If the name is available (1) add to the cart (2) checkout (3) pay using credit card. You will receive a confirmation email and instruction on how to manage and configure your domain.",
  },
  {
    id: 4,
    question: "What are the 7 new domain registries?",
    catagory: "faq-web3",
    answer: ".notarypublic | .notary | .taser | .pride",
  },
  {
    id: 5,
    question: "What are Premium Domains?",
    catagory: "faq-web3",
    answer:
      "Why is it that when you search ‘support.notary’ the price was more than $49.99? There are 98 domains that we have tagged as Premium and will require a higher fee to secure.",
  },
  {
    id: 6,
    question: "Why do I need to provide a Wallet address?",
    catagory: "faq-purchases",
    answer:
      "In order to engage with a blockchain network, all users need a wallet. This wallet functions as an account on the blockchain where transactions can be sent and received, and where cryptocurrency or NFTs can be securely stored. Personal information is not required to create a wallet, which consists of a public key for sending assets and a private key for accessing the wallet in the event of lost access or creating another instance. Your Web3 Domain Names are stored within your wallet. See MetaMask (link: https://metamask.io/)",
  },
  {
    id: 7,
    question: "Are there Renewal Fees?",
    catagory: "faq-purchases",
    answer:
      "The ownership of a Web3 Domain is permanent after the initial purchase, with no additional fees required for renewals. This distinguishes it from traditional domain options and offers a significant benefit to users.",
  },
  {
    id: 8,
    question: "Which Chains are Supported?",
    catagory: "faq-web3",
    answer:
      "Our approach for establishing domains involves multiple blockchains, providing flexibility to choose among Polygon, Cronos, and Binance for minting your Web3 domains. Additionally, we are looking to expand our multi-chain approach by adding more blockchains for minting domains.",
  },
  {
    id: 9,
    question: "Are the Domains Associated with ICANN?",
    catagory: "faq-web3",
    answer:
      "No, Web3 Domains are not associated with ICANN (Internet Corporation for Assigned Names and Numbers), which is the organization responsible for managing and coordinating domain names and IP addresses for the internet. Web3 Domains are a part of the decentralized web, and they use the Ethereum Name Service (ENS) protocol, which is a smart contract system that allows users to register human-readable names as Ethereum addresses on the blockchain. In other words, Web3 Domains are not part of the traditional internet infrastructure and are not governed by centralized entities like ICANN.",
  },
  {
    id: 10,
    question: "Will I be able to transfer my domain?",
    catagory: "faq-purchases",
    answer:
      "Yes. The domain is stored in your cryptocurrency wallet. After you mint your domain on the blockchain, you can transfer it anywhere you like.",
  },
  {
    id: 11,
    question: "Which cryptocurrencies can I use with my domain?",
    catagory: "faq-web3",
    answer:
      "You can currently map any of over 310 cryptocurrency addresses to an Unstoppable domain to make payments easier.",
  },
  {
    id: 12,
    question: "How can I view a Web3 website?",
    catagory: "faq-web3",
    answer:
      "You will need to use a mirroring service, a browser extension or a browser that supports Web3 domains.",
  },
  {
    id: 13,
    question: "How do payments work with my Web3 domain?",
    catagory: "faq-purchases",
    answer:
      "When someone types yourname.nft into any of the wallets that support our branded domain extensions, the wallet looks up that domain on the blockchain, finds the appropriate address, and sends the crypto to the address associated with that name. You can add your Bitcoin, Ethereum, and Zilliqa addresses to your Web3 domain inside of the ‘my domains’ section at unstoppabledomains.com.",
  },
];
