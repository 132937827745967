import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

export default function VideoModal({
  videoId,
  title,
  showYTVideo,
  setShowYTVideo,
}) {
  setTimeout(() => {
    new window.YT.Player("youtube-player", {
      videoId: videoId,
    });
  }, 500);

  return (
    <Modal
      show={showYTVideo}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div id="youtube-player"></div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowYTVideo(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
